import Splitting from "splitting";

import { $, $$, body } from "@utils/dom";


const splitByWordsForMaskAnimation = (el, options, ctx) => {
  const { words, lines } = ctx;
  words.forEach(word => word.innerHTML = `<span class="wordText">${word.innerHTML}</span>`);

  if(el.dataset.scrollTarget && lines){
    $(el.dataset.scrollTarget).style.setProperty('--line-total', lines.length);
  }

  return [];
};

// split by words and wrap each word content into another span to perform masking animation
Splitting.add({
  by: 'wordsMask',
  key: 'wordsMask',
  depends: ['words', 'lines'],
  split: splitByWordsForMaskAnimation,
});


export default (el = body) => {
  [ ...$$('[data-splitting]', el) ].forEach(el => {
    const splittingMethod = el.dataset.splitting || "wordsMask";

    Splitting({
      target: el,
      by: splittingMethod
    });
  });
}
