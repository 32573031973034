import anime from "animejs";

import { $ } from "@utils/dom";
import Viewport from "@utils/viewport";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-transition]";

class SiteTransition {
  constructor() {
    this.el = $(SELECTOR);
    this.exitLayer = $('path:first-child', this.el);
    this.enterLayer = $('path:last-child', this.el);

    this._exitDummy = { top: 1, curve: 1 };
    this._enterDummy = { top: 1, curve: 1 };

    this._updateExitLayerBnd = this._updateExitLayer.bind(this);
    this._updateEnterLayerBnd = this._updateEnterLayer.bind(this);
  }

  exit() {
    this.el.classList.remove("pointer-events-none");
    this.exitLayer.style.removeProperty('opacity');
    this.enterLayer.style.removeProperty('opacity');

    this._exitDummy.top = this._enterDummy.top = 1;
    this._exitDummy.curve = this._enterDummy.curve = 1;

    this._updateExitLayer();
    this._updateEnterLayer();

    return new Promise((resolve) => {
      anime({
        targets: this._exitDummy,
        keyframes: [
          { top: 0.5, curve: Viewport.width < 1024 ? 0.4 : 0.2, easing: 'easeInQuad' },
          { top: 0, curve: 0, easing: 'easeOutCubic' }
        ],
        duration: 650,
        begin: () => this.el.classList.remove("visibility-hidden"),
        update: this._updateExitLayerBnd,
        complete: resolve,
      });
    });
  }
  entering() {
    moduleDelays(350, 650);
  }
  enter() {
    return new Promise((resolve) => {
      const timeline = anime.timeline({ autoplay: false });

      timeline.add({
        targets: this._enterDummy,
        keyframes: [
          { top: 0.5, curve: Viewport.width < 1024 ? 0.4 : 0.2, easing: 'easeInQuad' },
          { top: 0, curve: 0, easing: 'easeOutCubic' }
        ],
        duration: 650,
        update: this._updateEnterLayerBnd,
      });

      timeline.add({ targets: this.exitLayer, opacity: 0, duration: 1 }, '+=0');
      timeline.add({
        targets: this.enterLayer,
        opacity: 0,
        duration: 250,
        easing: 'linear',
        complete: () => {
          this.el.classList.add("visibility-hidden", "pointer-events-none");
          resolve();
        },
      }, '+=0');

      timeline.play();
    });
  }

  _updateExitLayer() {
    const { top, curve } = this._exitDummy;
    const y = 880 * top;
    const anchor = 880 * curve;

    this.exitLayer.setAttribute('d', `M0,${y} Q960,${anchor} 1920,${y} L1920,880 L0,880 Z`);
  }
  _updateEnterLayer() {
    const { top, curve } = this._enterDummy;
    const y = 880 * top;
    const anchor = 880 * curve;

    this.enterLayer.setAttribute('d', `M0,${y} Q960,${anchor} 1920,${y} L1920,880 L0,880 Z`);
  }
}

export default SiteTransition;
