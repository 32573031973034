
import { $ } from "@utils/dom";
import { on, off } from "@utils/listener";
import { mobile } from "@utils/mobile";

class BounceButton {
  constructor(el) {

    this.el = el;

    this.target = $(this.el.dataset.bounceButton, this.el);

    this._hover = false;
    this._animated = false;

    this._onMouseEnter = this._onMouseEnter.bind(this);
    this._onMouseLeave = this._onMouseLeave.bind(this);
    this._onAnimationEnd = this._onAnimationEnd.bind(this);

    this.init();
  }

  init() {
    this._bindEvents();
  }
  destroy() {
    this._unbindEvents();

    this.el = null;
    this.target = null;

    this._hover = null;
    this._animated = null;

    this._onMouseEnter = null;
    this._onMouseLeave = null;
    this._onAnimationEnd = null;
  }

  _bindEvents() {
    if( mobile ) return;

    on(this.el, 'mouseenter', this._onMouseEnter);
    on(this.el, 'mouseleave', this._onMouseLeave);
  }
  _unbindEvents() {
    if( mobile ) return;
    
    off(this.el, 'mouseenter', this._onMouseEnter);
    off(this.el, 'mouseleave', this._onMouseLeave);
  }

  _onMouseEnter() {
    this._hover = true;
    this._animated = true;

    this.el.classList.add('--is-hover');

    if (this.target) {
      off(this.target, 'animationend', this._onAnimationEnd);
      on(this.target, 'animationend', this._onAnimationEnd);
    }
  }

  _onMouseLeave() {
    this._hover = false;

    if (!this.target || !this._animated && this.target) {
      this.el.classList.remove('--is-hover');
    }

  }

  _onAnimationEnd(){
    this._animated = false;

    if (!this._hover) {
      this.el.classList.remove('--is-hover');
    }
  }
}

export default BounceButton;
