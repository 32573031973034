import { EventType } from "@rive-app/canvas-lite";

import RiveAnimation from "@components/RiveAnimation";
import { $, body } from "@utils/dom";
import { moduleDelays } from "./utils";

const SELECTOR = "[data-site-loader]";

class SiteLoader {
  constructor() {
    this.el = $(SELECTOR);
    this.animation = $('.site-loader__animation', this.el);

    this._loaded = false;
    this._ready = false;
    this._resolve = null;

    this._onAnimationLoaded = this._onAnimationLoaded.bind(this);
    this._onAnimationExit = this._onAnimationExit.bind(this);
  }

  loaded() {
    moduleDelays(350, 2250);
  }
  init() {
    this.animation = new RiveAnimation(this.animation, { autoplay: true, isTouchScrollEnabled: false });
    this.animation.on('load', this._onAnimationLoaded);
    this.animation.instance.on(EventType.RiveEvent, this._onAnimationExit);
  }
  ready() {
    return new Promise((resolve) => {
      this._resolve = resolve;
      this._ready = true;

      if( this._loaded ) this._animate();
    });
  }
  done() {
    // destroy Rive animation
    if( this.animation ) {
      this.animation.instance.off(EventType.RiveEvent, this._onAnimationExit);
      this.animation.off('load', this._onAnimationLoaded);
      this.animation.destroy();
    }

    this.el = null;
    this.animation = null;

    this._loaded = null;
    this._ready = null;
    this._resolve = null;

    this._onAnimationLoaded = null;
    this._onAnimationExit = null;
  }

  _animate() {
    this.el.style.background = "transparent";

    const inputs = this.animation.instance.stateMachineInputs(this.animation.stateMachines);    
    const input = inputs.find(input => input.name === 'Ready');
    
    setTimeout(() => { input.value = true; }, 100);
  }
  _onAnimationLoaded() {
    this._loaded = true;
    if( this._ready ) this._animate();
  }
  _onAnimationExit(event) {
    if( event.data.name !== 'Exit' ) return;

    // stop animation
    if( this.animation ) this.animation.stop();

    // remove from DOM when completed
    if( this.el ) this.el.remove();

    // add class on body when transition is ready
    body.classList.add("--js-ready");

    // resolve transition
    this._resolve();
  }
}

export default SiteLoader;
