/**
* @core/windmill.buttons
* <br><br>
* ## Windmill Buttons.
*
* - Prepare buttons for special rollover effect.
*
* @module windmill
* @preferred
*/

import BounceButton from "@components/BounceButton";
import { $$ } from "@utils/dom";
import { mobile } from "@utils/mobile";

export const BTN_SELECTOR = "[data-bounce-button]:not(.pointer-events-none)";

export class WindmillButtons {  
  constructor() {
    this.buttons = null;
  }

  /**
  * Plugin installation.
  */
  install(windmill) {
    windmill.on('ready', this._initButtons, this);
    windmill.on('enter', this._initButtons, this);
    windmill.on('exited', this._destroyButtons, this);
  }

  _initButtons({ current, next }) {
    this.buttons = [];
    
    [ ...$$(BTN_SELECTOR, next.container || current.container) ].forEach(btn => {

      if( !mobile ) {
        this.buttons.push( new BounceButton(btn) );
      }
    });
  }
  _destroyButtons() {
    if( this.buttons ) this.buttons.forEach(btn => btn.destroy());
    this.buttons = null;
  }
}

export default WindmillButtons;
