export default [
  //"modules/accordions",
  //"modules/filtered-posts-list",
  //"modules/google-map",
  "modules/job-single",
  "modules/language-switcher",
  //"modules/lottie",
  //"modules/newsletter",
  "modules/pb-row-featured-posts",
  "modules/pb-row-founders",
  "modules/pb-row-hero",
  "modules/pb-row-jobs",
  //"modules/pb-row-medias",
  "modules/pb-row-newsletter",
  //"modules/pb-row-oembed",
  "modules/pb-row-page-header",
  "modules/pb-row-promo",
  "modules/pb-row-rich-scrolling-content",
  //"modules/pb-row-tabs",
  //"modules/pb-row-testimonials",
  //"modules/pb-row-wrapper-reveal",
  //"modules/prefers-color-scheme-btn",
  "modules/rive-animation",
  //"modules/scrolling-images-sequence",
  "modules/sharing",
  //"modules/svg-path-length",
  //"modules/text-ticker",
  "modules/video"
];
